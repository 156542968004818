import { updateSections, useTD } from '#td-next/state/useTD'
import { useTimeoutFn } from '@vueuse/core'

import { hash } from 'ohash'

export type FormState = 'clean' | 'dirty' | 'saving' | 'invalid'
export const formState = ref<FormState>('clean')

export const isTdFormSaving = ref(false)
export const isTdFormDirty = ref(false)
export const isTdFormValid = ref(true)

let tdFormLastValuesHash = {}

export function useTDForm(values: any, options?: {
  validate: () => Promise<boolean>
  confirm?: () => Promise<boolean>
}) {
  const auth = useAuth()
  const td = useTD()
  const nuxtApp = useNuxtApp()
  const toast = useAppToast()

  const value = toValue(values)
  tdFormLastValuesHash = hash({ ...toRaw(value) })
  watch(values, async (_value) => {
    isTdFormDirty.value = tdFormLastValuesHash !== hash(toRaw(_value))
    formState.value = 'dirty'
  }, { deep: true })

  // block navigation while dirty
  // Ignoring this for now
  // onBeforeRouteLeave((to, from, next) => {
  //   if (isTdFormDirty.value) {
  //     toast.add({
  //       color: 'yellow',
  //       title: 'You have unsaved changes',
  //       description: 'Please save your changes before leaving the page.',
  //     })
  //     next(false)
  //   }
  //   else {
  //     next()
  //   }
  // })

  const validate = async () => {
    if (options?.validate) {
      const valid = await options.validate()
      if (!valid) {
        formState.value = 'invalid'
        return false
      }
    }

    return true
  }

  const confirm = async () => {
    if (options?.confirm) {
      return await options.confirm()
    }

    return true
  }

  async function attemptFormSubmit(force: boolean = false) {
    // don't bother saving if nothing has changed
    if (!isTdFormDirty.value && !force) {
      return false
    }

    isTdFormSaving.value = true
    formState.value = 'saving'

    if (!await confirm()) {
      isTdFormSaving.value = false
      formState.value = 'dirty'
      return false
    }

    if (await validate()) {
      // if a quick adjust slideover is open, set up the context
      const sectionSlug = td.currentQuickAdjust?.sectionSlug || td.context.currentSection!.slug
      const currentSubSection = td.context.currentQuickAdjust || td.context.currentSubSection
      const sectionSaveFns: {
        [key: string]: (req: any) => Promise<any>
      } = {
        'token-profile': td.putTokenProfile,
        'modeling': td.putModeling,
        'valuation-documenting': td.putValuation,
        'adjusting': td.putAdjusting,
      }
      const saveFn = sectionSaveFns[sectionSlug]

      if (!saveFn) {
        throw new Error(`Unknown section ${sectionSlug}`)
      }

      return await saveFn({
        params: {
          projectId: auth.project!.id,
        },
        query: {
          complete: String(currentSubSection!.completed),
          preview: 'false',
        },
        body: {
          subSectionId: currentSubSection!.id as string,
          fields: toValue(values),
        },
      })
        .then((res) => {
          if (res && res.status >= 200 && res.status < 300) {
            if (res.body?.sections) {
              updateSections(res.body.sections)
            }

            // TODO may not need this
            isTdFormDirty.value = false
            formState.value = 'clean'
            tdFormLastValuesHash = hash({ ...toRaw(values.value) })
            toast.success({
              title: 'Your changes have been saved',
            })
          }
          // errors do not trigger the promise to fail
          else {
            // TODO handle actual validation errors
            toast.error({
              title: 'Some information provided requires your attention',
              description: 'Please ensure all provided data and calculations are valid before submitting changes.',
            })
          }
          return res
        })
        .catch((e: Error) => {
          // TODO handle actual validation errors
          if (e) {
            toast.error({
              title: 'Some information provided requires your attention',
              description: 'Please ensure all provided data and calculations are valid before submitting changes.',
            })
            throw e
          }
          return false
        })
        .finally(() => {
          useTimeoutFn(() => {
            isTdFormSaving.value = false
            formState.value = 'clean'
          }, 500)
        })
    }
    else {
      // TODO doubt we need this
      toast.error({
        title: 'Some information provided requires your attention',
        description: 'Please ensure all provided data and calculations are valid before submitting changes.',
      })
      isTdFormSaving.value = false
      formState.value = 'dirty'
    }
    return false
  }

  onMounted(() => {
    const rmHook = nuxtApp.hooks.hook('forgd:td-form-submission', async () => {
      await attemptFormSubmit()
    })
    onUnmounted(() => {
      rmHook()
    })
  })
  return {
    save(force?: boolean) {
      return attemptFormSubmit(force)
    },
  }
}
